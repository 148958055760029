export enum SortingOption {
    Popular = "Most Popular",
    PriceLowToHigh = "Price: Low to High",
    PriceHighToLow = "Price: High to Low",
}

// export enum Metals {
//     Gold = 1,
//     Silver = 2,
//     Platinum = 3,
//     Palladium = 4,
//     Bitcoin = 5,
//     AustralianDollar = 6,
//     Ethereum = 7,
//     BitcoinCash = 8,
//     Litecoin = 9,
//     Ripple = 10,
//     Copper = 11
// }

// export enum MetalTypes {
//     Bar = 0,
//     Coin = 1,
//     Unallocated = 2
// }

// export enum WeightTypes {
//     Ounces = 0,
//     Grams = 1,
//     Kilograms = 2
// }

export const Metals = {
    1: "Gold",
    2: "Silver",
    3: "Platinum",
    4: "Palladium",
    5: "Bitcoin",
    6: "AustralianDollar",
    7: "Ethereum",
    8: "BitcoinCash",
    9: "Litecoin",
    10: "Ripple",
    11: "Copper"
};

export const MetalTypes = {
    0: "Bar",
    1: "Coin",
    2: "Unallocated"
};

export const WeightTypes = {
    0: "Ounces",
    1: "Grams",
    2: "Kilograms"
};

export const AddressType = {
    Shipping: 2,
    Billing: 1
}

export enum PrivateHoldingAttachment {
    Receipt = 0,
    ProductPhotos = 1
}

export const PrivateHoldingDocumentTypeEnum: { [key: string]: string } = {
    "1": "Invoice",
    "2": "Certificate",
    "3": "Trading Statement",
    "4": "Vault Receipt",
    "5": "Valuation",
    "6": "Sale Contract",
    "7": "Other"
}
export const PrivateHoldingDocumentTypeReverseEnum: { [key: string]: string } = {
    "Invoice": "1",
    "Certificate": "2",
    "Trading Statement": "3",
    "Vault Receipt": "4",
    "Valuation": "5",
    "Sale Contract": "6",
    "Other": "7"
}

export const PageContentEnum: { [key: string]: string } =
{
    // [Description("Shop Home Page Section 3 Three pictures in a rows")]
    "ShopHomepage_Section_3_Three_pics_in_a_rows": "1",
    // [Description("Shop Home Page Section 5 One pictur and content")]
    "ShopHomepage_Section_5_One_pic_and_content": "2",
    // [Description("Home Page Section 1 Picture and content")]
    "Homepage_Section_1_Picture_and_content": "3",
    // [Description("Home Page Section 4 Two Pictures and content")]
    "Homepage_Section_4_Two_pics_and_content": "4",
    // [Description("Home Page Section 5 One Big Pictures and content")]
    "Homepage_Section_5_One_big_pic_and_content": "5",
    // [Description("Home Page Section 8 Footer Background Picture")]
    "Homepage_Section_8_Footer_background_pic": "6",
    // [Description("Shop Home Page Section 4 One Big Pictures and content")]
    "ShopHomepage_Section_4_One_big_pic_and_content": "7",
    // [Description("Shop Home Page Section 6 Two Pictures and content")]
    "Homepage_Section_6_Two_pics_and_content": "8",
    // [Description("Chart page content")]
    "Chartpage_content": "9",
    // [Description("Calculators page content")]
    "Calculatorspage_content": "10",
    // [Description("Vault Calculator page content")]
    "VaultCalculatorpage_content": "11",
    // [Description("Shipping Calculator page content")]
    "ShippingCalculatorpage_content": "12"
}

export enum TopicContentTypeEnum {
    CKediter = 0,
    Section = 1
}

export enum SectionTypeEnum {
    SectionFirst = 1,
    SectionSecond = 2,
    SectionThird = 3,
    SectionFourth = 4,
    SectionFifth = 5,
    SectionSixth = 6,
    SectionSeventh = 7,
    SectionEighth = 8,
    SectionNinth = 9,
    SectionTenth = 10,
    SectionEleventh = 11,
    SectionTwelfth = 12,
    SectionThirteenth = 13,
}

export enum GtagTrackEvents {
    view_cart = 1,
    add_to_cart = 2,
    remove_from_cart = 3,
    begin_checkout = 4,
    purchase = 5
}